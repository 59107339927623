export function useLinkHirarcy() {
  return {
    auth: {
      login: "/auth",
      signout: "/signout",
    },
    org: {
      create: "/create",
      reports: {
        index: `/reports`,
        aiInsights: `/reports/ai-insights`,
        voiceOfCustomer: `/reports/voice-of-customer`,
        dataExport: `/reports/data-export`,
        handoffs: `/reports/handoffs`,
        wrapped2024: `/wrapped-2024`,
      },
      inbox: {
        index: `/inbox`,
        session: ({
          sessionId,
          historyId,
        }: { sessionId: string; historyId?: string }) => {
          const historySuffix = historyId ? `&h=${historyId}` : "";
          return `/inbox/?s=${sessionId}${historySuffix}`;
        },
      },
      settings: {
        index: `/settings`,
        appearance: `/settings/appearance`,
        accessToken: `/settings/access`,
        context: `/settings/context`,
        aiAutopilot: `/settings/autopilot`,
        integrations: `/settings/integrations`,
        domains: `/settings/domains`,
        officeHours: `/settings/office-hours`,
        teammates: `/settings/teammates`,
        tags: `/settings/tags`,
        groups: `/settings/groups`,
        aiEvals: `/settings/evals`,
        webhooks: `/settings/webhooks`,
        security: `/settings/security`,
        handoff: `/settings/handoff`,
        ticketing: `/settings/ticketing`,
        jira: `/settings/jira`,
        knowledgebase: `/settings/knowledgebase`,
        phoneNumbers: `/settings/phone-numbers`,
        agentAssignment: `/settings/agent-assignment`,
        variables: `/settings/variables`,
      },
      channels: {
        index: `/channels/`,
        configure: {
          web: `/channels/configure/widget`,
          email: `/channels/configure/mail`,
        },
        slack: `/channels/slack`,
        sms: {
          index: `/channels/sms`,
        },
        whatsapp: {
          index: `/channels/whatsapp`,
        },
        phone: {
          index: `/channels/phone`,
          create: `/channels/phone/create`,
          createWithTemplate: (templateId: string) =>
            `/channels/phone/create?template_id=${templateId}`,
          agents: `/channels/phone/agents`,
          agent: (agentId: string) => `/channels/phone/agents/${agentId}`,
        },
      },
      email: {
        index: `/e`,
        new: `/e/new`,
        newToOne: (to: string) => `/e/new?to=${to}`,
        templates: {
          index: `/e/templates`,
          edit: (templateId: string) => `/e/templates/${templateId}`,
        },
      },
      sequencing: {
        index: `/seq`,
        sequence: (seqId: string) => `/seq/${seqId}`,
      },
      workflows: {
        create: `/workflows/new`,
        index: `/workflows/`,
        workflow: (workflowId: string) => `/workflows/${workflowId}`,
      },
      contacts: {
        index: `/contacts/`,
        contact: (contactId: string) => `/contacts/${contactId}/deepview`,
      },
      helpCenter: {
        index: `/help-center/`,
        create: `/help-center/create`,
        edit: (helpCenterId: string) => `/help-center/${helpCenterId}/edit`,
        helpCenter: (helpCenterId: string) => `/help-center/${helpCenterId}`,
        categories: {
          category: (helpCenterId: string, categoryId: string) =>
            `/help-center/${helpCenterId}/?category-id=${categoryId}`,
        },
      },
      actions: {
        index: `/actions/`,
        newAction: `/actions/new`,
        action: (actionId: string) => `/actions/${actionId}`,
      },
      knowledge: {
        index: `/ai-knowledge/`,
      },
      training: {
        index: `/training`,
      },
    },
  } as const;
}
